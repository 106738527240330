// video thumbnail
import thumbnail1 from "../../assets/images/naadu/video-thumb/naadu-tamil-film-thumbnail-1.jpg";
import thumbnail2 from "../../assets/images/naadu/video-thumb/naadu-tamil-film-thumbnail-2.jpg";
import thumbnail3 from "../../assets/images/naadu/video-thumb/naadu-tamil-film-thumbnail-3.jpg";
import thumbnail4 from "../../assets/images/naadu/video-thumb/naadu-tamil-film-thumbnail-4.jpg";
import thumbnail5 from "../../assets/images/naadu/video-thumb/naadu-tamil-film-thumbnail-5.jpg";
import thumbnail6 from "../../assets/images/naadu/video-thumb/naadu-tamil-film-thumbnail-6.jpg";
import thumbnail7 from "../../assets/images/naadu/video-thumb/naadu-tamil-film-thumbnail-7.jpg";
import thumbnail8 from "../../assets/images/naadu/video-thumb/naadu-tamil-film-thumbnail-8.jpg";
import thumbnail9 from "../../assets/images/naadu/video-thumb/naadu-tamil-film-thumbnail-9.jpg";
import thumbnail10 from "../../assets/images/naadu/video-thumb/naadu-tamil-film-thumbnail-10.jpg";
import thumbnail11 from "../../assets/images/naadu/video-thumb/yelelaai-song-from-naadu.jpg";
import RecentUpdates from "./RecentUpdates";

const RecentUpdatesData = [
  {
    name: "Yelelaai Lyric Video | Naadu | Tharshan | Mahima Nambiar | C.Sathya | M.Saravanan",
    image: [thumbnail11],
    videoUrl: "/yelelaai-lyric-video",
  },
  {
    name: "Naadu - Official Trailer | Tharshan | Mahima Nambiar | C.Sathya| M.Saravanan",
    image: [thumbnail10],
    videoUrl: "/naadu-official-trailer",
  },
  {
    name: "Maavilai Maavilai Thorananga Video Song | Naadu | Tharshan, Mahima Nambiar | C.Sathya | M.Saravanan",
    image: [thumbnail9],
    videoUrl: "/maavilai-maavilai-thorananga-video-song",
  },
  {
    name: "Maavilai Maavilai Thorananga Lyric Video | Naadu | Tharshan, Mahima Nambiar | C.Sathya| M.Saravanan",
    image: [thumbnail8],
    videoUrl: "/maavilai-maavilai-thorananga-lyric-video-song",
  },
  {
    name: "Glimpse of #NAADU #நாடு First Look Launch | M.Saravanan | Tharshan & MahimaNambiar | @SriArchMedia",
    image: [thumbnail7],
    videoUrl: "/glimpse-of-naadu-fist-look",
  },
  {
    name: "கதை ரொம்ப புடிச்சிருக்கு | Mahima Nambiar Fun 😂 Speech at #Naadu Press Meet",
    image: [thumbnail1],
    videoUrl: "/mahima-Nambiar-fun-speech",
  },
  {
    name: "Singampuli Comedy🤣 Speech at Naadu First Look Release | 👏கைதட்டி சிரித்த Mahima, Tharshan !",
    image: [thumbnail2],
    videoUrl: "singampuli-fun-speech",
  },
  {
    name: "Tharshan Fun Speech @Naadu #நாடு Press Meet | First Look Launch | Sri Arch Media | M.Saravanan Movie",
    image: [thumbnail3],
    videoUrl: "/tharshan-fun-speech-at-naadu-pressmeet",
  },
  {
    name: "Music Director C.Sathya speak about #Naadu | First Look Launch | Sriarchmedia",
    image: [thumbnail4],
    videoUrl: "/music-director-csathya-speak-about-naadu",
  },
  {
    name: "Director M. Saravanan Speech @ Naadu Press Meet",
    image: [thumbnail5],
    videoUrl: "/director-msaravanan-speech-at-naadu-pressmeet",
  },
  {
    name: "#NAADU Production #1 | M.Saravanan Film Press Meet | Sri Arch Media | Tharshan | Mahima Nambiar",
    image: [thumbnail6],
    videoUrl: "naadu-production-press-meet",
  },
];

export default RecentUpdatesData;
