import { React } from "react";
import { Row, Col } from "antd";
import { Link } from "react-router-dom";
import Yelelaai from "../../../assets/images/naadu/video-thumb/yelelaai-song-from-naadu.jpg";

const HashTags = [
  "#Yelelaai",
  "#Naadu",
  "#Tharshan",
  "#Yelelaai",
  "#Naadu",
  "#Tharshan",
  "#MahimaNambiar",
  "#Sathya",
  "#Suganthi",
  "#Thozhan",
  "#YelelaaiLyricVideo",
];

function YelelaaiLyricVideo() {
  return (
    <>
      <section className="video-gallery bg-image spacing">
        <div className="container">
          <Row
            gutter={[
              { xs: 0, sm: 16, md: 24, lg: 32 },
              { xs: 0, sm: 16, md: 24, lg: 20 },
            ]}
            align="center"
            className="pb-3 "
          >
            <Col
              xs={{ span: 24 }}
              sm={{ span: 18 }}
              md={{ span: 18 }}
              lg={{ span: 18 }}
            >
              <h1>Yelelaai Lyric Video from Naadu</h1>
              <iframe
                className="iframe-video"
                src="https://www.youtube.com/embed/x9peKfZjnQU"
                title="Yelelaai Lyric Video | Naadu | Tharshan | Mahima Nambiar | C.Sathya | M.Saravanan"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              />
            </Col>
          </Row>
        </div>
      </section>

      <section className=" bg-white spacing">
        <div className="container">
          <Row
            gutter={[
              { xs: 0, sm: 16, md: 24, lg: 32 },
              { xs: 0, sm: 16, md: 24, lg: 20 },
            ]}
            align="center"
            className="pb-3 "
          >
            <Col
              xs={{ span: 24 }}
              sm={{ span: 18 }}
              md={{ span: 18 }}
              lg={{ span: 18 }}
            >
              <p className="mb-3">
                Watch the Lyric Video of "Yelelaai" from "Naadu", Sung by
                Suganthi, Lyrics Written by Thozhan, Music Composed by C.
                Sathya.
              </p>
              <img
                src={Yelelaai}
                alt="Yelelaai from naadu"
                className="w-100 mb-3"
              />
              <h5 className="mb-2">Song Credits:</h5>
              <ul>
                <li>Song Name : Yelelaai</li>
                <li>Movie : Naadu</li>
                <li>Composed And Arranged : C. Sathya</li>
                <li>Lyrics : Thozhan</li>
                <li>Singer : Suganthi</li>
                <li>Keys Programmed : Sai Abhyankkar</li>
                <li>Multi Percussions : Sarvesh Karthick </li>
                <li>
                  Song Mix And Mastered : Sai Shravanam @ ResoundIndia Studios.
                  Chennai.
                </li>
              </ul>
              <h5>Movie Credits:</h5>
              <ul>
                <li>
                  Cast : Tharshan, Mahima Nambiar, Singam Puli, R.S.Sivaji, Arul
                  Das, Inba Ravikumar, Vasantha and Others
                </li>
                <li>Director : M. Saravanan</li>
                <li>Music : C. Sathya</li>
                <li>DOP : K.A. Sakthivel</li>
                <li>Editor : Pon Kathiresh PK</li>
                <li>Art Director : Lalgudi N.Ilaiya Raja</li>
                <li>Co Director : Ramesh Andavan </li>
                <li>Line Producer : Praveen</li>
                <li>Sound Mix : Knack Studio</li>
                <li>DI : Lixo Creation </li>
                <li>Creative Producer : Akash Balaji</li>
                <li>Stills : Manju</li>
                <li>Publicity Designer : Shabeer</li>
                <li>Produced By : Chakra & Raj</li>
                <li>Banner : Sri Arch Media Pvt Ltd.</li>
              </ul>
              <h5>Audio Label : Think Music</h5>

              <div className="hashtags">
                <ul className="m-0 p-0">
                  {HashTags.map((tag, i) => (
                    <li key={i}>
                      <Link
                        to="/yelelaai-lyric-video"
                        rel="noreferrer"
                        className="mr-2"
                      >
                        {tag}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
}

export default YelelaaiLyricVideo;
