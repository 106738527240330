import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { Row, Col, Button } from "antd";
import "./Team.scss";
import Team1 from "../../assets/images/team/team-1.png";
import Team2 from "../../assets/images/team/team-2.png";
import Team3 from "../../assets/images/team/team-3.png";
import MissionImage from "../../assets/images/our-missions.png";

export default class Team extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section className="banner-section bg-red pattern-texture py-5">
          <div className="container mx-auto py-3 ">
            <Row
              gutter={[12, 22]}
              className="social-media text-center mt-4 pb-3"
            >
              <Col
                className="center-contents"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
              >
                <div className="middle-content">
                  <h1 className="d-block w-100 text-uppercase text-white mb-0">
                    Our Team
                  </h1>
                </div>
              </Col>
            </Row>
          </div>
        </section>

        <section className="team-profile pattern-5 py-5">
          <div className="container mx-auto py-5 my-3">
            <Row gutter={[30, 20]} align="center" className="pb-3 ">
              <Col
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 8 }}
                lg={{ span: 8 }}
              >
                <div className="pb-2 text-center">
                  <img src={Team1} alt="" />
                  <h3 className="text-red text-uppercase mt-2">
                    JEFFREY BROWN
                  </h3>
                  <span className="mt-2 pb-1">Creative Leader</span>
                  <p className="text-center">
                    Glavi amet ritnisl libero molestie ante ut fringilla
                    puruseros quis glavrid from dolor amet iquam lorem bibendum
                  </p>
                </div>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 8 }}
                lg={{ span: 8 }}
              >
                <div className="pb-2 text-center">
                  <img src={Team2} alt="" />
                  <h3 className="text-red text-uppercase mt-2">
                    JEFFREY BROWN
                  </h3>
                  <span className="mt-2 pb-1">Creative Leader</span>
                  <p className="text-center">
                    Glavi amet ritnisl libero molestie ante ut fringilla
                    puruseros quis glavrid from dolor amet iquam lorem bibendum
                  </p>
                </div>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 8 }}
                lg={{ span: 8 }}
              >
                <div className="pb-2 text-center">
                  <img src={Team3} alt="" />
                  <h3 className="text-red text-uppercase mt-2">
                    JEFFREY BROWN
                  </h3>
                  <span className="mt-2 pb-1">Creative Leader</span>
                  <p className="text-center">
                    Glavi amet ritnisl libero molestie ante ut fringilla
                    puruseros quis glavrid from dolor amet iquam lorem bibendum
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
