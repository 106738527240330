import { React } from "react";
import RecentUpdatesData from "./RecentUpdatesData";
import { Row, Col } from "antd";
import { Link } from "react-router-dom";

function RecentUpdates() {
  return (
    <section className="video-gallery bg-light-blue spacing">
      <div className="container">
        <h3 className="mb-3 text-center">Recent Updates from Naadu</h3>
        <Row
          gutter={[
            { xs: 0, sm: 16, md: 24, lg: 32 },
            { xs: 0, sm: 16, md: 24, lg: 20 },
          ]}
          align="center"
          className="pb-3 "
        >
          {RecentUpdatesData.map((videos, index) => (
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}
              key={index}
            >
              <Link to={`${videos.videoUrl}`}>
                <div className="card">
                  <img
                    src={videos.image}
                    alt={`${videos.name}`}
                    title={`${videos.name}`}
                  />
                  <h4>{videos.name}</h4>
                </div>
              </Link>
            </Col>
          ))}
        </Row>
      </div>
    </section>
  );
}

export default RecentUpdates;
