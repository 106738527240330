import React from "react";
import { Col, Row } from "antd";
import { Link } from "react-router-dom";
import { FadeIn } from "react-animated-components";
// import movieList_1 from "../../../assets/images/movies/movie-1.jpg";
// import movieList_2 from "../../../assets/images/movies/movie-2.jpg";
// import movieList_3 from "../../../assets/images/movies/movie-3.jpg";
// import movieList_4 from "../../../assets/images/movies/movie-4.jpg";
import naaduMovie from "../../../assets/images/naadu/naadu-movie-releasing-on-dec-1st-poster.jpg";

// const moviesList = [
//   {
//     id: 1,
//     image: [movieList_1],
//     title: "Naadu",
//     link: "/naadu",
//   },
//   {
//     id: 2,
//     image: [movieList_2],
//     title: "Coming Soon",
//     class: "coming-soon",
//     link: "#",
//   },
//   {
//     id: 3,
//     image: [movieList_3],
//     title: "Coming Soon",
//     class: "coming-soon",
//     link: "#",
//   },
//   {
//     id: 4,
//     image: [movieList_4],
//     title: "Coming Soon",
//     class: "coming-soon",
//     link: "#",
//   },
// ];

export default class UpcomingMovies extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section className="upcoming-movies pattern-texture py-5">
          <div className="container mx-auto ">
            <h1 className="text-white text-center text-uppercase pb-2 mb-3">
              UPCOMING MOVIES IN THEATER
            </h1>
            <p className="text-white text-center mt-3">
              Naadu WorldWide Release on Dec 1st 2023
            </p>
            <div className="w-100 text-center">
              <img src={naaduMovie} className="w-75" alt="Naadu" />
            </div>
            {/* <Row gutter={[5, 10]}>
              {moviesList.map((list, index) => (
                <Col
                  key={list.id}
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  md={{ span: 6 }}
                  lg={{ span: 6 }}
                >
                  <Link to={`${list.link}`}>
                    <div key={list.id} className={`${list.class} item`}>
                      <FadeIn className="pb-2">
                        <div className="image">
                          <img
                            src={list.image}
                            title={`${list.title}`}
                            alt={`${list.title}`}
                            className="m-auto w-100"
                          />
                        </div>
                        <div className="description">
                          <p className="text-center mt-1">{list.title}</p>
                        </div>
                      </FadeIn>
                    </div>
                  </Link>
                </Col>
              ))}
            </Row> */}
          </div>
        </section>
      </React.Fragment>
    );
  }
}
