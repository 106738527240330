import { React } from "react";
import { Row, Col } from "antd";

function GlimpseOfNaaduFistLook() {
  return (
    <>
      <section className="video-gallery bg-image spacing">
        <div className="container">
          <Row
            gutter={[
              { xs: 0, sm: 16, md: 24, lg: 32 },
              { xs: 0, sm: 16, md: 24, lg: 20 },
            ]}
            align="center"
            className="pb-3 "
          >
            <Col
              xs={{ span: 24 }}
              sm={{ span: 18 }}
              md={{ span: 18 }}
              lg={{ span: 18 }}
            >
              <h1>Glimpse of #NAADU #நாடு First Look Launch</h1>
              <iframe
                className="iframe-video"
                src="https://www.youtube.com/embed/p9d7Ec0Uxhg"
                title="Glimpse of #NAADU #நாடு First Look Launch  | M.Saravanan | Tharshan &amp; MahimaNambiar | @SriArchMedia"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              />
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
}

export default GlimpseOfNaaduFistLook;
