import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { Row, Col } from "antd";
// import movieList_1 from "../../assets/images/movies/movie-1.jpg";
// import movieList_2 from "../../assets/images/movies/movie-2.jpg";
// import movieList_3 from "../../assets/images/movies/movie-3.jpg";
// import movieList_4 from "../../assets/images/movies/movie-4.jpg";
import { FadeIn, SlideInDown } from "react-animated-components";
import naaduMovie from "../../assets/images/naadu/naadu-movie-releasing-on-dec-1st-poster.jpg";

// const allMovies = [
//   {
//     image: [movieList_1],
//     title: "Naadu",
//     link: "/naadu",
//   },
//   {
//     image: [movieList_2],
//     title: "Coming Soon",
//     class: "coming-soon",
//     link: "#",
//   },
//   {
//     image: [movieList_3],
//     title: "Coming Soon",
//     class: "coming-soon",
//     link: "#",
//   },
//   {
//     image: [movieList_4],
//     title: "Coming Soon",
//     class: "coming-soon",
//     link: "#",
//   },
// ];
export default class Naadu extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section className="banner-section bg-movie-pattern py-5">
          <div className="container mx-auto py-3 ">
            <Row
              gutter={[12, 22]}
              className="social-media text-center mt-4 pb-3"
            >
              <Col
                className="center-contents"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
              >
                <div className="middle-content">
                  <h1 className="d-block w-100 text-uppercase text-white mb-0">
                    Sri Arch Media Movies
                  </h1>
                </div>
              </Col>
            </Row>
          </div>
        </section>

        <section className="movies-list py-5">
          <div className="container mx-auto ">
            {/* <h3 className="text-black text-left text-uppercase pb-2 mb-3">
              All Movies
            </h3> */}
            <div className="text-center">
              <h4 className="mb-3">Naadu WorldWide Release on Dec 1st 2023</h4>
              <img
                src={naaduMovie}
                className="w-75"
                at="Naadu WorldWide Release on Dec 1st 2023"
              />
            </div>

            {/* <Row gutter={[30, 20]} align="center" className="pb-3 ">
              {allMovies.map((list, index) => (
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  md={{ span: 6 }}
                  lg={{ span: 6 }}
                  key={list.id}
                >
                  <Link to={`${list.link}`}>
                    <div className={`${list.class} item`}>
                      <FadeIn className="pb-2">
                        <div className="image">
                          <img
                            src={list.image}
                            title={`${list.title}`}
                            alt={`${list.title}`}
                            className="m-auto w-100"
                          />
                        </div>
                        <div className="description">
                          <p className="text-center mt-1">{list.title}</p>
                        </div>
                      </FadeIn>
                    </div>
                  </Link>
                </Col>
              ))}
            </Row> */}
          </div>
        </section>
      </React.Fragment>
    );
  }
}
