import { React } from "react";
import { Row, Col } from "antd";
import { Link } from "react-router-dom";
import poster from "../../../assets/images/naadu/video-thumb/naadu-tamil-film-thumbnail-8.jpg";

const HashTags = [
  "#Naadu",
  "#Viveka",
  "#Tharshan",
  "#MaavilaiMaavilaiThorananga",
  "#Naadu",
  "#Tharshan",
  "#MahimaNambiar",
  "#Sathya",
  "#AnthonyDaasan",
  "#Viveka",
  "#MaavilaiMaavilaiThoranangaLyricVideo",
];

const musicLinks = [
  { label: "Spotify", url: "https://spoti.fi/42H55kL" },
  { label: "Youtube Music", url: "https://bit.ly/421eh3e" },
  { label: "Apple Music", url: "https://apple.co/3LzinbT" },
  { label: "Amazon Music", url: "https://amzn.to/4119fCx" },
  { label: "JioSaavn", url: "https://bit.ly/44r1B7t" },
  { label: "Resso", url: "https://bit.ly/415lQop" },
  { label: "Hungama", url: "https://bit.ly/3p5JHaa" },
  { label: "Instagram Audio", url: "https://bit.ly/3LUZrpq" },
];

function MaavilaiMaavilaiThoranangaLyricVideo() {
  return (
    <>
      <section className="video-gallery bg-image spacing">
        <div className="container">
          <Row
            gutter={[
              { xs: 0, sm: 16, md: 24, lg: 32 },
              { xs: 0, sm: 16, md: 24, lg: 20 },
            ]}
            align="center"
            className="pb-3 "
          >
            <Col
              xs={{ span: 24 }}
              sm={{ span: 18 }}
              md={{ span: 18 }}
              lg={{ span: 18 }}
            >
              <h1>Maavilai Maavilai Thorananga Lyric Video Song </h1>

              <iframe
                className="iframe-video"
                src="https://www.youtube.com/embed/2nt_jh5ZkAU"
                title="Maavilai Maavilai Thorananga Lyric Video | Naadu | Tharshan, Mahima Nambiar | C.Sathya| M.Saravanan"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              />
            </Col>
          </Row>
        </div>
      </section>

      <section className=" bg-white spacing">
        <div className="container">
          <Row
            gutter={[
              { xs: 0, sm: 16, md: 24, lg: 32 },
              { xs: 0, sm: 16, md: 24, lg: 20 },
            ]}
            align="center"
            className="pb-3 "
          >
            <Col
              xs={{ span: 24 }}
              sm={{ span: 18 }}
              md={{ span: 18 }}
              lg={{ span: 18 }}
            >
              <p className="mb-3">
                Here's the Official Lyric Video of "Maavilai Maavilai
                Thorananga" from "Naadu", Sung by Anthony Daasan, Lyrics Written
                by Viveka, Music Composed by C. Sathya. Produced by Sri Arch
                Media Pvt Ltd & Directed by M.Saravanan
              </p>
              <img
                src={poster}
                alt="Official Trailer of Naadu"
                className="w-100 mb-3"
              />

              <h5>Song Credits :</h5>

              <ul>
                <li>Song Name : Maavilai Maavilai Thorananga</li>
                <li>Movie : Naadu</li>
                <li>Composed and Arranged : C.Sathya</li>
                <li>Singer : Anthony Daasan</li>
                <li>Lyrics : Viveka</li>
                <li>Keys Programmed by Sai Abhyankkar</li>
                <li>Trumpet, Trombone : Babu</li>
                <li>Shenai and Clarinet : Das</li>
                <li>Percussions : Vetri, Hariprasad, Ranjith Magi</li>
                <li>
                  Backing Vocals : RJ Bruse, Inzamam-Ul-Haq, Sudharshan Ajay,
                  Honeyblaze ,Kiran, Irfan Hyder
                </li>
                <li>Voice Recorded at Soundtown Studios by Mithun</li>
                <li>
                  Song Mix and Master by Sai Shravanam @ Resoundindia Studios,
                  Chennai
                </li>
              </ul>

              <h5>
                Listen to #MaavilaiMaavilaiThorananga Song from #Naadu on your
                favourite streaming apps:
              </h5>

              <ul>
                {musicLinks.map((data, i) => (
                  <li>
                    {data.label}
                    <a
                      className="d-inline ms-2"
                      href={data.url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {data.url}
                    </a>
                  </li>
                ))}
              </ul>

              <h5>Movie Credits :</h5>

              <ul>
                <li>
                  Cast : Tharshan, Mahima Nambiar, Singam Puli, R.S.Sivaji, Arul
                  Das, Inba Ravikumar, Vasantha
                </li>
                <li>Director : M.Saravanan</li>
                <li>Music : C.Sathya</li>
                <li>DOP : K.A.Sakthivel</li>
                <li>Editor : Pon Kathiresh PK</li>
                <li>Art Director : Lalgudi N.Ilaiya Raja</li>
                <li>Co Director : Ramesh Andavan</li>
                <li>Choreographer : Dina</li>
                <li>Stills : Manju</li>
                <li>Line Producer : Praveen</li>
                <li>Publicity Designer : Shabeer</li>
                <li>Produced by : Chakra & Raj</li>
                <li>Banner : Sri Arch Media Pvt Ltd</li>
              </ul>
              <h5>Audio Label : Think Music</h5>

              <div className="hashtags">
                <ul className="m-0 p-0">
                  {HashTags.map((tag, i) => (
                    <li key={i}>
                      <Link
                        to="/maavilai-maavilai-thorananga-lyric-video-song"
                        className="mr-2"
                      >
                        {tag}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
}

export default MaavilaiMaavilaiThoranangaLyricVideo;
