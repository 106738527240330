import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { Row, Col } from "antd";
import ContactForm from "./ContactForm";
import "./ContactUs.scss";
import phoneIcon from "../../assets/images/icons/phone.svg";
import emailIcon from "../../assets/images/icons/contact-email.svg";
import locationIcon from "../../assets/images/icons/contact-location.svg";
import clock from "../../assets/images/icons/clock.svg";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

class ContactUs extends React.Component {
  render() {
    const defaultProps = {
      center: {
        lat: 13.1125071,
        lng: 80.1806331,
      },
      zoom: 11,
    };

    return (
      <React.Fragment>
        <section className="banner-section bg-red-yellow-pattern  py-5">
          <div className="container mx-auto py-3 ">
            <Row
              gutter={[
                { xs: 0, sm: 16, md: 22, lg: 22 },
                { xs: 0, sm: 16, md: 20, lg: 20 },
              ]}
              className="social-media text-center mt-4 pb-3"
            >
              <Col
                className="center-contents"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
              >
                <div className="middle-content">
                  <h1 className="d-block w-100 text-uppercase text-white mb-0">
                    Contact Sri Arch Media
                  </h1>
                </div>
              </Col>
            </Row>
          </div>
        </section>

        <section className="contact-us pattern-6 py-5">
          <div className="container mx-auto ">
            <Row
              gutter={[
                { xs: 0, sm: 16, md: 30, lg: 30 },
                { xs: 0, sm: 16, md: 20, lg: 20 },
              ]}
              align="center"
              className="pb-3 "
            >
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
                className="pr-lg-5"
              >
                <p className="mb-3 short-dec">
                  Give us a call or drop by anytime, we endeavour to answer all
                  enquiries within 24 hours on business days. We will be happy
                  to answer your questions.
                </p>
                <div className="contact-informations">
                  <div className="contact-list mb-3">
                    <div className="icons">
                      <img src={phoneIcon} alt="Phone Number" />
                    </div>
                    <div className="details">
                      <span>Phone Number</span>
                      <p>+91-7305838412</p>
                    </div>
                  </div>
                  <div className="contact-list mb-3">
                    <div className="icons">
                      <img src={emailIcon} alt="Email Address" />
                    </div>
                    <div className="details">
                      <span>Email Address</span>
                      <p>info@sriarchmedia.com</p>
                    </div>
                  </div>
                  <div className="contact-list mb-3">
                    <div className="icons">
                      <img src={locationIcon} alt="Location Details" />
                    </div>
                    <div className="details">
                      <span>Office Address</span>
                      <h6>Sri Arch Media </h6>
                      <p>
                        1021, 45th street,
                        <br /> Periyar Nagar, Korattur,
                        <br /> Chennai, 600 080
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 10 }}
                lg={{ span: 10 }}
              >
                <div className="get-in-touch">
                  <h2>Get In Touch</h2>
                  <p>We are here for you! How can we help?</p>
                  <ContactForm />
                </div>
              </Col>
            </Row>
          </div>
        </section>

        <section className="working-time bg-dark-black-pattern py-5">
          <div className="container mx-auto ">
            <Row
              gutter={[
                { xs: 0, sm: 16, md: 22, lg: 30 },
                { xs: 0, sm: 16, md: 20, lg: 20 },
              ]}
              align="center"
            >
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 10 }}
              >
                <div className="d-flex border-right">
                  <img
                    src={clock}
                    alt="Sri Arch Media Working Hours"
                    className="mr-4"
                  />
                  <h3 className="text-white">
                    Working Hours
                    <br /> for Sri Arch Media
                  </h3>
                </div>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 10 }}
              >
                <ul className="text-white">
                  <li>
                    <span>Monday to Saturday:</span> : 9AM – 6PM
                  </li>
                  <li>
                    <span>Sunday:</span> : 12PM – 6 PM
                  </li>
                </ul>
              </Col>
            </Row>
          </div>
        </section>

        <section className="sri-arch-map pattern-1 py-5">
          <div className="container mx-auto ">
            <Row
              gutter={[
                { xs: 0, sm: 16, md: 30, lg: 30 },
                { xs: 0, sm: 16, md: 20, lg: 20 },
              ]}
              align="center"
              className="pb-3 "
            >
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
              >
                <iframe
                  className="w-100"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d242.86185417124545!2d80.18063311818631!3d13.112507057169454!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52639af5c40a83%3A0xe6329b9f357576bb!2sSri%20Arch%20Media!5e0!3m2!1sen!2sin!4v1661752243636!5m2!1sen!2sin"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                />
              </Col>
            </Row>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
export default withRouter(ContactUs);
