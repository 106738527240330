import React from "react";
import "./Header.scss";
import { Link } from "react-router-dom";
import mainlogo from "../assets/images/standed-camera-repair.png";

export let setHeader = false;
export default class Header extends React.Component {
  constructor() {
    super();
    this.state = {
      openMenu: false,
    };
  }
  componentDidMount() {
    window.onscroll = function () {
      myFunction();
    };

    var header = document.getElementById("main-hearder");
    var sticky = header.offsetTop;

    function myFunction() {
      if (window.pageYOffset > sticky) {
        header.classList.add("scrolled");
      } else {
        header.classList.remove("scrolled");
      }
    }
    //Onscroll sticky Header function ends here
  }

  render() {
    return (
      <React.Fragment>
        <div className="main-hearder" id="main-hearder">
          <div className="container mx-auto">
            <div className="top-header">
              <div className="left-section">
                <div className="logo">
                  <Link to="/">
                    <img
                      src={mainlogo}
                      alt="Sri Arch Media and Entertainment"
                    />
                    <span className="text-black">Sri Arch Media</span>
                  </Link>
                </div>
              </div>
              <div
                id="hamburger"
                className={this.state.openMenu ? "menuopened" : ""}
                onClick={() =>
                  this.setState({ openMenu: !this.state.openMenu })
                }
              />

              <div
                className={
                  this.state.openMenu ? "navigations opened" : "navigations"
                }
              >
                <div className="center-header">
                  <ul>
                    <li>
                      <Link
                        to="/"
                        onClick={() => this.setState({ openMenu: false })}
                      >
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/about-us"
                        onClick={() => this.setState({ openMenu: false })}
                      >
                        About us
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/naadu"
                        onClick={() => this.setState({ openMenu: false })}
                      >
                        Naadu
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/movies"
                        onClick={() => this.setState({ openMenu: false })}
                      >
                        Movies
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/news"
                        onClick={() => this.setState({ openMenu: false })}
                      >
                        News
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="text-right text-uppercase">
                  <Link
                    to="./contact-us"
                    onClick={() => this.setState({ openMenu: false })}
                    className="btn btn-primary"
                  >
                    Contact
                  </Link>
                </div>
              </div>
            </div>

            {/* bottom header starts */}

            {/* bottom header ends */}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
