import { React } from "react";
import { Row, Col } from "antd";
import { Link } from "react-router-dom";
import poster from "../../../assets/images/naadu/video-thumb/naadu-tamil-film-thumbnail-10.jpg";

const HashTags = [
  "#Naadu",
  "#Tharshan",
  "#MahimaNambiar",
  "#SingamPuli",
  "#Sathya",
  "#Saravanan",
  "#NaaduTrailer",
  "#NaaduTamilMovie",
  "#NaaduMovieTrailer",
  "#NaaduMovieTamil",
];

function NaaduOfficialTrailer() {
  return (
    <>
      <section className="video-gallery bg-image spacing">
        <div className="container">
          <Row
            gutter={[
              { xs: 0, sm: 16, md: 24, lg: 32 },
              { xs: 0, sm: 16, md: 24, lg: 20 },
            ]}
            align="center"
            className="pb-3 "
          >
            <Col
              xs={{ span: 24 }}
              sm={{ span: 18 }}
              md={{ span: 18 }}
              lg={{ span: 18 }}
            >
              <h1>Naadu - Official Trailer </h1>
              <iframe
                className="iframe-video"
                src="https://www.youtube.com/embed/9C9-YPnaFCM"
                title="Naadu - Official Trailer | Tharshan | Mahima Nambiar | C.Sathya| M.Saravanan"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              />
            </Col>
          </Row>
        </div>
      </section>

      <section className=" bg-white spacing">
        <div className="container">
          <Row
            gutter={[
              { xs: 0, sm: 16, md: 24, lg: 32 },
              { xs: 0, sm: 16, md: 24, lg: 20 },
            ]}
            align="center"
            className="pb-3 "
          >
            <Col
              xs={{ span: 24 }}
              sm={{ span: 18 }}
              md={{ span: 18 }}
              lg={{ span: 18 }}
            >
              <p className="mb-3">
                Here's the Official Trailer of "Naadu", Starring Tharshan,
                Mahima Nambiar, Singam Puli & Others, Music Composed by
                C.Sathya, Directed by M.Saravanan, Produced by Chakra & Raj.
              </p>
              <img
                src={poster}
                alt="Official Trailer of Naadu"
                className="w-100 mb-3"
              />

              <h5>Movie Credits :</h5>
              <ul>
                <li>
                  Cast : Tharshan, Mahima Nambiar, Singam Puli, R.S.Sivaji, Arul
                  Das, Inba Ravikumar, Vasantha
                </li>
                <li>Director : M.Saravanan</li>
                <li>Music : C.Sathya</li>
                <li>
                  Singers : Gold Antony Das, Manoj Krishna, Diwakar, Irfan,
                  Suganthi, Devaraj, Muthu Sirpy, Puratchi Mani, Nehru Samudurai{" "}
                </li>
                <li>
                  Lyrics : M.Saravanan, Viveka, Va Karuppan, Thozhan, Karthik
                  Netha
                </li>
                <li>DOP : K.A.Sakthivel</li>
                <li>Editor : Pon Kathiresh PK</li>
                <li>Art Director : Lalgudi N.Ilaiya Raja</li>
                <li>Co Director : Ramesh Andavan</li>
                <li>Choreographer : Sathish, Dina, Azar</li>
                <li>Sound Mix : Knack Studio</li>
                <li>DI : Lixo Creation</li>
                <li>Stills : Manju</li>
                <li>Creative Producer : Akash Balaji</li>
                <li>Line Producer : Praveen</li>
                <li>Publicity Designer : Shabeer</li>
                <li>Produced by : Chakra & Raj</li>
                <li>Banner : Sri Arch Media Pvt Ltd</li>
              </ul>

              <h5>Music Credits :</h5>
              <ul>
                <li>Composed and Arranged by C.Sathya</li>
                <li>Singer : Gunasundari</li>
                <li>Flute : Sriram</li>
                <li>Additional Programming : Sai Abhyankkar</li>
                <li>
                  Live percussions : Salem Kaaturajaavin Aadhi Gramiya Isai
                  Kuzhu
                </li>
                <li>Live Percussions Recorded by RK Sundar and Sarvesh</li>
                <li>Mix and Mastered by KS Abhishek @ S Studio Chennai</li>
              </ul>

              <div className="hashtags">
                <ul className="m-0 p-0">
                  {HashTags.map((tag, i) => (
                    <li key={i}>
                      <Link
                        to="/naadu-official-trailer"
                        rel="noreferrer"
                        className="mr-2"
                      >
                        {tag}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
}

export default NaaduOfficialTrailer;
