import React from "react";
import "./Header.scss";
import { Link } from "react-router-dom";
import { Row, Col, BackTop } from "antd";
import { UpOutlined } from "@ant-design/icons";
import "./Footer.scss";
import whatsapp from "../assets/images/icons/whatsapp.svg";
import location from "../assets/images/icons/location.svg";
import email from "../assets/images/icons/email.svg";

export default class Footer extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section className="footer">
          <div className="container mx-auto">
            <Row gutter={[16, { xs: 8, sm: 15, md: 20, lg: 22 }]}>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 6 }}
                lg={{ span: 6 }}
              >
                <div className="quick-links">
                  <h4 className="text-red">Quick Links</h4>
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="#">Team</Link>
                    </li>
                    <li>
                      <Link to="/movies">Movies</Link>
                    </li>
                    <li>
                      <Link to="/news">News</Link>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 6 }}
                lg={{ span: 6 }}
              >
                <div className="quick-links">
                  <h4 className="text-red">About Sri Arch Media</h4>
                  <ul>
                    <li>
                      <Link to="/about-us">Our Company</Link>
                    </li>
                    <li>
                      <Link to="/about-us">Our Vision</Link>
                    </li>
                    <li>
                      <Link to="/about-us">Our Mission</Link>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 6 }}
                lg={{ span: 6 }}
              >
                <div className="contact-details">
                  <h4 className="text-red">Reach us</h4>
                  <div className="contact-list">
                    <div className="icon">
                      <img src={whatsapp} alt="Whatsapp / Phone" />
                    </div>
                    <div className="details">
                      <span className="text-yellow">Whatsapp / Phone</span>
                      <p>+91-7305838412</p>
                    </div>
                  </div>
                  <div className="contact-list">
                    <div className="icon">
                      <img src={email} alt="Email Address" />
                    </div>
                    <div className="details">
                      <span className="text-yellow">Email Address</span>
                      <p>info@sriarchmedia.com</p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 6 }}
                lg={{ span: 6 }}
              >
                <div className="contact-details">
                  <h4 className="text-red">Office Location</h4>
                  <div className="contact-list">
                    <div className="icon">
                      <img src={location} alt="Sri Arch Media" />
                    </div>
                    <div className="details">
                      <span className="text-yellow">Sri Arch Media</span>
                      <p>
                        1021, 45th street, Periyar Nagar, Korattur, Chennai, 600
                        080
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row
              gutter={[12, 22]}
              className="social-media text-center mt-4 pb-3"
            >
              <h5 className="d-block w-100 text-uppercase text-gray mb-0">
                Follow us on social media
              </h5>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
              >
                <div className="social-media-links">
                  <a
                    href="https://www.facebook.com/Sri-Arch-Media-102797419199171"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i class="fa fa-facebook" aria-hidden="true" />
                  </a>
                  <a
                    href="https://instagram.com/sriarchmedia"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i class="fa fa-instagram" aria-hidden="true" />
                  </a>
                  <a
                    href="https://youtube.com/@sriarchmedia"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i class="fa fa-youtube-play" aria-hidden="true" />
                  </a>
                  <a
                    href="https://twitter.com/sriarchmedia"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i class="fa fa-twitter" aria-hidden="true" />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/sriarchmedia"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i class="fa fa-linkedin" aria-hidden="true" />
                  </a>
                  <a
                    href="https://in.pinterest.com/sriarchmedia/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i class="fa fa-pinterest" aria-hidden="true" />
                  </a>
                </div>
              </Col>
            </Row>
            <Row gutter={[12, 22]} className="copyright-text pb-3">
              <Col
                className="text-center"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
              >
                <p className="text-gray pt-4 ">
                  © {new Date().getFullYear()} Copyright Sri Arch Media and
                  Entertainment. All Right Reserved.
                </p>
              </Col>
            </Row>
          </div>
        </section>
        <BackTop>
          <div className="back-to-top">
            <UpOutlined />
          </div>
        </BackTop>
      </React.Fragment>
    );
  }
}
