import React from "react";
import { Row, Col, Image } from "antd";
import Slider from "react-slick";
import "./Movies.scss";

import naaduMovieBanner from "../../assets/images/naadu/naadu-movie.jpg";

// movie posters
import poster1 from "../../assets/images/naadu/posters/naadu-tamil-movie-first-look.JPG";
import poster2 from "../../assets/images/naadu/posters/naadu-tamil-movie-firstlook-invite-poster.jpeg";
import poster3 from "../../assets/images/naadu/posters/naadu-poster.jpg";
import poster4 from "../../assets/images/naadu/posters/naadu-poster-2.JPG";
import poster5 from "../../assets/images/naadu/posters/naadu-poster-3.jpg";
import poster6 from "../../assets/images/naadu/posters/naadu-poster-4.jpg";
import poster7 from "../../assets/images/naadu/posters/naadu-tamil-movie-pongal-wishes.jpg";
import poster8 from "../../assets/images/naadu/posters/Jan-26th-2.jpg";
import poster9 from "../../assets/images/naadu/posters/Ugadi.jpg";
import RecentUpdates from "./RecentUpdates";

const posterSlidersettings = {
  arrows: true,
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 2,
  autoplay: true,
  autoplaySpeed: 5000,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 2,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ],
};

const naaduPosters = [
  {
    name: "Naadu tamil movie first look release by Makkal Selvan vijay sethupathi",
    image: [poster1],
  },
  {
    name: "Naadu tamil movie first look Press Release invite poster",
    image: [poster2],
  },
  {
    name: "Naadu tamil movie first look Press Release invite poster",
    image: [poster3],
  },
  {
    name: "Naadu tamil movie first look Press Release invite poster",
    image: [poster4],
  },
  {
    name: "Naadu tamil movie first look Press Release invite poster",
    image: [poster5],
  },
  {
    name: "Naadu tamil movie first look Press Release invite poster",
    image: [poster6],
  },
  {
    name: "Naadu tamil movie first look Press Release invite poster",
    image: [poster7],
  },
  {
    name: "Naadu tamil movie first look Press Release invite poster",
    image: [poster8],
  },
  {
    name: "Naadu tamil movie first look Press Release invite poster",
    image: [poster9],
  },
];
const movieDetails = [
  {
    title: "DIRECTOR",
    details: "M Saravanan",
  },
  {
    title: "PRODUCED BY",
    details: "Sri Arch Media",
  },
  {
    title: "WRITTEN BY",
    details: "M Saravanan",
  },
  {
    title: "STARRING",
    details: "Tharshan Thiyagarajah & Mahima Nambiar",
  },
  {
    title: "MUSIC BY",
    details: "C Sathya",
  },
  {
    title: "CINEMATOGRAPHY",
    details: "KA Sakthivel",
  },
  {
    title: "EDITED BY",
    details: "PK",
  },
  {
    title: "Genres",
    details: "Drama",
  },
];
export default class Naadu extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section className="movie-banner-image">
          <img
            src={naaduMovieBanner}
            title="Naadu- A M.Saravanan Film"
            alt="Naadu- A M.Saravanan Film"
          />
          <div className="title-desc">
            <div className="container">
              <div className="content">
                <h1 className="text-white">Naadu</h1>
                <p className="text-white mb-2">
                  Naadu is an upcoming Indian Tamil-language film directed by M.
                  Saravanan and will feature Tharshan Thiyagarajah and Mahima
                  Nambiar as lead characters, The feature film is produced by
                  Chakra and Raj and the music composed by C. Sathya.
                </p>
                <h4 className="text-white mb-0">
                  Naadu WorldWide Release on Dec 1st 2023
                </h4>
              </div>
            </div>
          </div>
        </section>

        <section className="about-naadu spacing">
          <div className="container">
            <Row
              gutter={[
                { xs: 0, sm: 16, md: 24, lg: 32 },
                { xs: 0, sm: 16, md: 24, lg: 20 },
              ]}
              className="pb-3 "
            >
              <Col
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
              >
                <h3 className="mb-3">About Naadu</h3>
                <p>
                  Naadu is an upcoming Indian Tamil-language film directed by M.
                  Saravanan and will feature Tharshan Thiyagarajah and Mahima
                  Nambiar as lead characters, The feature film is produced by
                  Chakra and Raj. The film starring Tharshan Thiyagarajah in
                  leading role. The film's music composed by C.Sathya, while
                  cinematography and editing is handled by Shakthi and PK
                  respectively.
                </p>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
              >
                <div className="details p-4 bg-light-pink">
                  <ul>
                    {movieDetails.map((data, index) => (
                      <li key={data.id}>
                        <span>{data.title} :</span>
                        {data.details}
                      </li>
                    ))}
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
        </section>

        <section className="poster-image bg-light-brown spacing">
          <div className="container">
            <h3 className="mb-3 text-center">Naadu Posters</h3>
            {/* <Image.PreviewGroup
              preview={{
                onChange: (current, prev) =>
                  console.log(`current index: ${current}, prev index: ${prev}`),
              }}
            > */}
            {/* <Row gutter={[30, 20]} className="pb-3 "> */}
            <Slider {...posterSlidersettings}>
              {naaduPosters.map((images, index) => (
                <div key={index} className="list">
                  <Image
                    src={images.image}
                    title={`${images.name}`}
                    alt={`${images.name}`}
                  />
                </div>
              ))}
            </Slider>
            {/* </Row> */}
            {/* </Image.PreviewGroup> */}
          </div>
        </section>
        <section className="video-gallery bg-white spacing">
          <div className="container">
            <Row
              gutter={[
                { xs: 0, sm: 16, md: 24, lg: 32 },
                { xs: 0, sm: 16, md: 24, lg: 20 },
              ]}
              align="center"
              className="pb-3 "
            >
              <Col
                xs={{ span: 24 }}
                sm={{ span: 18 }}
                md={{ span: 18 }}
                lg={{ span: 18 }}
              >
                <h3 className="mb-3 text-center">Naadu - Official Trailer</h3>
                <iframe
                  className="iframe-video"
                  src="https://www.youtube.com/embed/9C9-YPnaFCM"
                  title="Naadu - Official Trailer | Tharshan | Mahima Nambiar | C.Sathya| M.Saravanan"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                />
                <h4 className="text-center mt-3">
                  Naadu - Official Trailer | Tharshan | Mahima Nambiar |
                  C.Sathya| M.Saravanan
                </h4>
              </Col>
            </Row>
          </div>
        </section>
        <RecentUpdates />
      </React.Fragment>
    );
  }
}
