import React from "react";
import { withRouter } from "react-router";
import Slider from "react-slick";
import "./HomeSlider.scss";
import NaaduReleasing from "../../../assets/images/main-slider/releasing-on-dec-1st.jpg";
import NaaduFirstLook from "../../../assets/images/main-slider/naadu-firstlook-release.jpg";
import YelelaaiSong from "../../../assets/images/main-slider/yelelaai-video-song.jpg";
import CycleEvent from "../../../assets/images/main-slider/cycle-event.jpg";
import { Link } from "react-router-dom";

const sliderCarousel = [
  {
    title: "Naadu WorldWide Release on Dec 1st 2023",
    image: NaaduReleasing,
    link: "/naadu-official-trailer",
  },
  {
    title: "Yelelaai Lyric Video",
    image: YelelaaiSong,
    link: "/yelelaai-lyric-video",
  },
  {
    title: "Cycle Event",
    image: CycleEvent,
    link: "#",
  },
];

const Mainslidersettings = {
  arrows: true,
  dots: true,
  infinite: true,
  speed: 300,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerMode: true,
  centerPadding: "60px",
  // variableWidth: true,
  autoplay: true,
  autoplaySpeed: 5000,
  responsive: [
    {
      breakpoint: 600,
      settings: {
        arrows: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        arrows: false,
      },
    },
  ],
};

class HomeSlider extends React.Component {
  render() {
    return (
      <React.Fragment>
        {/* <!--Main Slider section starts--> */}
        <div className="archmedia-slider">
          <div className="slider-content ">
            {/* <!--Slider Item starts--> */}
            <Slider {...Mainslidersettings}>
              {sliderCarousel.map((list, index) => (
                <div key={list.id} className="item">
                  <Link to={list.link}>
                    <img src={list.image} alt={list.title} />
                  </Link>
                </div>
              ))}
            </Slider>
          </div>
        </div>
        {/* <!--Main Slider section ends--> */}
      </React.Fragment>
    );
  }
}
export default withRouter(HomeSlider);
