import React from "react";
import { Row, Col } from "antd";
import VisionImage from "../../assets/images/our-vision.png";
import MissionImage from "../../assets/images/our-missions.png";

export default class AboutUs extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section className="banner-section bg-red-yellow-pattern py-5">
          <div className="container mx-auto py-3 ">
            <Row
              gutter={[12, 22]}
              className="social-media text-center mt-4 pb-3"
            >
              <Col
                className="center-contents"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
              >
                <div className="middle-content">
                  <h1 className="d-block w-100 text-uppercase text-white mb-0">
                    About Sri Arch Media
                  </h1>
                  <h2 className="text-uppercase text-white mb-0">
                    And Entertainment
                  </h2>
                </div>
              </Col>
            </Row>
          </div>
        </section>

        <section className="why-sri-arch-media pattern-1 spacing">
          <div className="container mx-auto">
            <Row gutter={[30, 20]} align="center" className="pb-3 ">
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 18 }}
                lg={{ span: 18 }}
                className="center-contents"
              >
                <div className="pb-2 middle-content">
                  <p className="text-center">
                    Sri Arch Media is an Indian entertainment company, which was
                    established by Raj and friends in 2022. The production
                    company has been involved in the production and distribution
                    of films made in South India. The Vision of Sri Arch media
                    is to create, promote and release content across varying
                    genres.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </section>

        <section className="our-vision bg-gray pattern-4 py-5">
          <div className="container mx-auto">
            <Row gutter={[30, 20]} align="center" className="pb-3 ">
              <Col
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
                className="center-contents"
              >
                <div className="pb-2 middle-content">
                  <h2 className="text-red text-uppercase mb-2">Our Vision</h2>
                  <p>
                    Sri Arch Media is a new bee in the film industry aiming to
                    provide service and support to the entertainment industry.
                    Our focus ensures that Sri Arch Media is and will always be
                    a renowned production center.
                  </p>
                </div>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 10 }}
                lg={{ span: 10 }}
                className="center-contents"
              >
                <img
                  className="m-auto w-75"
                  src={VisionImage}
                  alt="Sri Arch Media Visions"
                  title="Sri Arch Media Visions"
                />
              </Col>
            </Row>
          </div>
        </section>

        <section className="our-mission pattern-5 py-5">
          <div className="container mx-auto">
            <Row gutter={[30, 20]} align="center" className="pb-3 ">
              <Col
                xs={{ span: 24, order: 1 }}
                sm={{ span: 12, order: 1 }}
                md={{ span: 12, order: 2 }}
                lg={{ span: 12, order: 2 }}
                className="center-contents"
              >
                <div className="pb-2 middle-content">
                  <h2 className="text-red text-uppercase mb-2">OUR MISSION</h2>
                  <p>
                    Sri arch media builds, supports and markets india’s
                    entertainment industry. by collaborating with the indigenous
                    entertainment community, we strive to implement innovative
                    ways to grow our industry, provide the hands-on, best in
                    class service that our clients need and deserve, and exceed
                    expectation of our customers and present ourselves as one of
                    the market leaders.
                  </p>
                </div>
              </Col>
              <Col
                xs={{ span: 24, order: 2 }}
                sm={{ span: 12, order: 2 }}
                md={{ span: 10, order: 1 }}
                lg={{ span: 10, order: 1 }}
                className="center-contents"
              >
                <img
                  className="m-auto w-75"
                  src={MissionImage}
                  alt="Sri Arch Media Visions"
                  title="Sri Arch Media Visions"
                />
              </Col>
            </Row>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
