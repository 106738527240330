import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "../Screens/LandingPage/Home";
import AboutUs from "../Screens/AboutUs/AboutUs";
import Team from "../Screens/Team/Team";
import Movies from "../Screens/Movies/Movies";
import Naadu from "../Screens/Movies/Naadu";
import News from "../Screens/News/News";
import ContactUs from "../Screens/ContactUs/ContactUs";
import Layout from "../Layout/Layout";
import YelelaaiLyricVideo from "../Screens/Movies/Naadu/YelelaaiLyricVideo";
import NaaduOfficialTrailer from "../Screens/Movies/Naadu/NaaduOfficialTrailer";
import MaavilaiMaavilaiThoranangaVideoSong from "../Screens/Movies/Naadu/MaavilaiMaavilaiThoranangaVideoSong";
import MaavilaiMaavilaiThoranangaLyricVideo from "../Screens/Movies/Naadu/MaavilaiMaavilaiThoranangaLyricVideo";
import GlimpseOfNaaduFistLook from "../Screens/Movies/Naadu/GlimpseOfNaaduFistLook";
import MahimaNambiarFunSpeech from "../Screens/Movies/Naadu/MahimaNambiarFunSpeech";
import SingampuliFunSpeech from "../Screens/Movies/Naadu/SingampuliFunSpeech";
import TharshanFunSpeechNaadu from "../Screens/Movies/Naadu/TharshanFunSpeechNaadu";
import MusicDirectorCsathyaSpeakAboutNaadu from "../Screens/Movies/Naadu/MusicDirectorCsathyaSpeakAboutNaadu";
import DirectorMsaravananSpeechNaaduPressMeet from "../Screens/Movies/Naadu/DirectorMsaravananSpeechNaaduPressMeet";
import NaaduProduction from "../Screens/Movies/Naadu/NaaduProduction";

class InnerRoutes extends React.Component {
  render() {
    const {
      match: { url },
    } = this.props;
    return (
      <Layout {...this.props}>
        <Switch>
          <Route exact path={`/`} component={Home} />
          <Route path="/about-us" component={AboutUs} />
          <Route path="/team" component={Team} />
          <Route path="/movies" component={Movies} />
          <Route path="/naadu" component={Naadu} />
          <Route path="/news" component={News} />
          <Route path="/contact-us" component={ContactUs} />
          {/* naadu movie web pages */}
          <Route path="/yelelaai-lyric-video" component={YelelaaiLyricVideo} />
          <Route
            path="/naadu-official-trailer"
            component={NaaduOfficialTrailer}
          />
          <Route
            path="/maavilai-maavilai-thorananga-video-song"
            component={MaavilaiMaavilaiThoranangaVideoSong}
          />
          <Route
            path="/maavilai-maavilai-thorananga-lyric-video-song"
            component={MaavilaiMaavilaiThoranangaLyricVideo}
          />
          <Route
            path="/glimpse-of-naadu-fist-look"
            component={GlimpseOfNaaduFistLook}
          />
          <Route
            path="/mahima-Nambiar-fun-speech"
            component={MahimaNambiarFunSpeech}
          />
          <Route
            path="/singampuli-fun-speech"
            component={SingampuliFunSpeech}
          />
          <Route
            path="/tharshan-fun-speech-at-naadu-pressmeet"
            component={TharshanFunSpeechNaadu}
          />
          <Route
            path="/music-director-csathya-speak-about-naadu"
            component={MusicDirectorCsathyaSpeakAboutNaadu}
          />
          <Route
            path="/director-msaravanan-speech-at-naadu-pressmeet"
            component={DirectorMsaravananSpeechNaaduPressMeet}
          />
          <Route
            path="/naadu-production-press-meet"
            component={NaaduProduction}
          />
        </Switch>
      </Layout>
    );
  }
}

export default InnerRoutes;
